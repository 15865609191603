import { useContext, useLayoutEffect, useEffect } from 'react';
import { useGetEmployeesQuery } from '../../../api/clientPartApi/apiEmployeesSlice';

import { useGetClientEmployeeQuery } from '../../../api/apiCommonClientEmployeesSlice';

import Section from '../../../layouts/Section/Section';
import EmployeesList from './EmployeesList';
import EmployeesForm from './EmployeesForm';

import { socket } from '../../Page';

import { PageContext } from '../../Page';
import { PageContainerContext } from '../../PageContainer';

const Employees = () => {
  const { role, dictionary } = useContext(PageContext);
  const {
    createSlug,
    content,
    changeContentHandler,
    skip,
    selectedElementId,
    hash,
  } = useContext(PageContainerContext);

  useLayoutEffect(() => {
    if (hash === '#invite' && content !== 'inviteEmployee') {
      changeContentHandler('inviteEmployee');
    } else if (hash.includes('#employee') && content !== 'editEmployee') {
      const id = hash.slice(hash.indexOf('_') + 1);
      changeContentHandler('editEmployee', id);
    } else if (!hash && content !== 'employeesList') {
      changeContentHandler('employeesList');
    }
  }, [hash]);

  const {
    data: employees,
    isLoading: employeesLoading,
    isFetching: employeesFetching,
    isSuccess: employeesSuccess,
    isError: employeesFetchingError,
    error: employeesError,
    refetch: fetchEmployees,
  } = useGetEmployeesQuery(
    {
      limit: createSlug('limit'),
      page: createSlug('page'),
      sortBy: createSlug('sort_by'),
      sortOrder: createSlug('sort_order'),
      search: createSlug('q'),
      role_id: createSlug('role_id'),
      sites: createSlug('sites', 'array'),
    },
    { skip }
  );

  const {
    data: selectedEmployee,
    isLoading: selectedEmployeeLoading,
    isFetching: selectedEmployeeFetching,
    isError: selectedEmployeeFetchingError,
  } = useGetClientEmployeeQuery(
    content === 'editEmployee'
      ? { id: selectedElementId, adminPart: false }
      : '',
    {
      skip: content !== 'editEmployee',
    }
  );

  const activeEmployees = employees?.data?.employees?.active
  const maxActiveEmployees = employees?.data?.employees?.max
  const employeesList = employees?.data?.employees?.data || [];
  const employeesListPaginationData = employees?.data?.employees || {};
  const employee = selectedEmployee?.data || [];
  const employeesWorkTime = employees?.data?.employees?.workTime;

  useEffect(() => {
    if (content === 'employeesList') {
      fetchEmployees();
    }
    const updateEmployeesList = (data) => {
      if (content === 'employeesList') {
        fetchEmployees();
      }
    };

    socket.on('client.update', updateEmployeesList);

    return () => {
      socket.off('client.update', updateEmployeesList);
    };
  }, [content]);

  return (
    <>
      <Section
        key={selectedEmployee?.data?.id}
        isLoading={employeesLoading || selectedEmployeeLoading}
        isFetching={employeesFetching || selectedEmployeeFetching}
        isError={employeesFetchingError || selectedEmployeeFetchingError}
        error={employeesError}
        title={
          content === 'employeesList'
            ? dictionary?.employees_title_1
            : content === 'inviteEmployee'
            ? dictionary?.employees_title_2
            : dictionary?.employees_title_3
        }
        sx={{ maxWidth: content === 'employeesList' ? 920 : 540 }}
        content={content}
      >
        {(!content || content === 'employeesList') && (
          <EmployeesList
            userRole={role}
            employees={employees}
            activeEmployees={activeEmployees}
            maxActiveEmployees={maxActiveEmployees}
            employeesList={employeesList}
            paginationData={employeesListPaginationData}
            employeesSuccess={employeesSuccess}
          />
        )}
        {(content === 'inviteEmployee' || content === 'editEmployee') && (
          <EmployeesForm
            isWorkScheduleEnabled={employees?.data?.isWorkScheduleEnabled}
            actionType={content === 'inviteEmployee' ? 'invite' : 'edit'}
            employee={content === 'editEmployee' ? employee : ''}
            employeesWorkTime={employeesWorkTime}
            part={'clientPart'}
          />
        )}
      </Section>
    </>
  );
};

export default Employees;
