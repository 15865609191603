import { apiSlice } from '../apiSlice';

const apiDialoguesSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getDialoguesList: builder.query({
            query: (query) => ({
                url: `/client/dialogs${query.limit}${query.page}${query.sortBy}${query.sortOrder}${query.search}${query.sites}${query.user}${query.visitor}${query.tag}${query.country}${query.status}${query.range_start}${query.range_end}`,
            }),
            invalidatesTags: ['Dialogues'],
        }),
        getDialogues: builder.query({
            query: (query) => ({
                url: `client/dialogs/${query.status}`,
            }),
        }),
        getDialogueTags: builder.query({
            query: () => ({
                url: 'client/dialogs/tags',
            }),
            providesTags: ['Dialogues Tags'],
        }),
        getDialogue: builder.query({
            query: (id) => ({
                url: `client/dialogs/${id}`,
            }),
            providesTags: ['Visitors', 'Dialogues'],
        }),
        getDialogueVisitorData: builder.query({
            query: (id) => ({
                url: `client/dialogs/${id}/visitor`,
            }),
            providesTags: ['Dialogues', 'Visitors'],
        }),
        connectToDialogue: builder.mutation({
            query: (id) => ({
                url: `client/dialogs/${id}/connect`,
                method: 'POST',
            }),
            invalidatesTags: ['Dialogues', 'Visitors'],
        }),
        clearDialoguesViews: builder.mutation({
            query: (id) => ({
                url: `client/dialogs/${id}/viewed/clear`,
                method: 'POST',
            }),
            invalidatesTags: ['Dialogues'],
        }),
        changeDialogueStatus: builder.mutation({
            query: (id) => ({
                url: `client/dialogs/${id}/resolve`,
                method: 'POST',
            }),
            invalidatesTags: ['Dialogues', 'Visitors'],
        }),
        passDialogue: builder.mutation({
            query: (data) => ({
                url: `client/dialogs/${data.dialogue}/pass?user_id=${data.user}`,
                method: 'POST',
            }),
            invalidatesTags: ['Dialogues'],
        }),
        getCurrentDialogueTags: builder.query({
            query: (id) => ({
                url: `client/dialogs/${id}/tags`,
            }),
            providesTags: ['Dialogues'],
        }),
        changeCurrentDialogueTags: builder.mutation({
            query: (data) => ({
                url: `client/dialogs/${data.id}/tags?${data.tags}`,
                method: 'POST',
            }),
            invalidatesTags: ['Dialogues Tags', 'Dialogues'],
        }),
        visitorContactsRequest: builder.mutation({
            query: (id) => ({
                url: `client/dialogs/${id}/request`,
                method: 'POST',
            }),
        }),
        getChatMessages: builder.query({
            query: (id) => ({
                url: `client/dialogs/${id}`,
            }),
        }),
        postMessage: builder.mutation({
            query: ({ id, text }) => ({
                url: `client/dialogs/${id}?text=${text}`,
                method: 'POST',
            }),
        }),
        postMessageFile: builder.mutation({
            query: ({ id, file }) => ({
                url: `client/dialogs/${id}`,
                method: 'POST',
                body: file,
            }),
        }),
        editMessage: builder.mutation({
            query: ({ id, messageId, text }) => ({
                url: `client/dialogs/${id}/${messageId}?text=${text}`,
                method: 'PUT',
            }),
        }),
        deleteMessage: builder.mutation({
            query: ({ id, messageId }) => ({
                url: `client/dialogs/${id}/${messageId}`,
                method: 'DELETE',
            }),
        }),
        saveMessage: builder.mutation({
            query: ({ id, messageId }) => ({
                url: `client/dialogs/${id}/${messageId}/save`,
                method: 'POST',
            }),
        }),
        unsaveMessage: builder.mutation({
            query: ({ id, messageId }) => ({
                url: `client/dialogs/${id}/${messageId}/unsave`,
                method: 'DELETE',
            }),
        }),
        findSavedMessage: builder.mutation({
            query: (text) => ({
                url: `client/answers/search/?q=${text}`,
                method: 'GET',
            }),
        }),
        findCommand: builder.query({
            query: ({ text }) => ({
                url: `client/commands/search/?q=${text}`,
            }),
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetDialoguesListQuery,
    useGetDialoguesQuery,
    useGetDialogueQuery,
    useGetDialogueTagsQuery,
    useGetDialogueVisitorDataQuery,
    useConnectToDialogueMutation,
    useClearDialoguesViewsMutation,
    useChangeDialogueStatusMutation,
    usePassDialogueMutation,
    useGetCurrentDialogueTagsQuery,
    useChangeCurrentDialogueTagsMutation,
    useVisitorContactsRequestMutation,
    useGetChatMessagesQuery,
    usePostMessageMutation,
    usePostMessageFileMutation,
    useEditMessageMutation,
    useDeleteMessageMutation,
    useSaveMessageMutation,
    useUnsaveMessageMutation,
    useFindSavedMessageMutation,
    useFindCommandQuery,
} = apiDialoguesSlice;
