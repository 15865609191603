import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
  useLayoutEffect,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ChatBody } from './ChatBody';
import { ChatFooter } from './ChatFooter';

import { useGetMessagesQuery } from '../../api/clientPartApi/apiCommonDialogs';
import { clearMessages, setDialogHistory } from '../../reducers/messagesSlice';

import { socket } from '../../pages/Page';

export const ChatStyled = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  border: `1px solid #DAECF9`,
  borderRadius: '5px',
  backgroundColor: theme.palette.basicColor.colorWhite,
}));
//
// const link = document.querySelector("link[rel*='icon']");
// let notificationCounter = 0;
//
// const updateFavicon = (notificationCount) => {
//   const canvas = document.createElement('canvas');
//   const context = canvas.getContext('2d');
//
//   canvas.height = 34;
//   canvas.width = 34;
//
//   const img = document.createElement('img');
//   img.src = `${window.location.origin}/favicon.png`;
//
//   img.addEventListener('load', () => {
//     context.drawImage(img, 0, 0, canvas.width, canvas.height);
//
//     if (notificationCount > 0) {
//       context.fillStyle = 'red';
//       context.beginPath();
//       context.arc(canvas.width - 10, 24, 10, 0, 2 * Math.PI);
//       context.fill();
//
//       context.fillStyle = 'white';
//       context.textAlign = 'center';
//       context.font = 'bold 17px sans-serif';
//       context.fillText(
//         notificationCount > 99 ? '99+' : notificationCount.toString(),
//         canvas.width - 10,
//         31
//       );
//     }
//
//     link.href = canvas.toDataURL('image/png');
//   });
// };
//
// const increaseNotificationCounter = () => {
//   notificationCounter++;
//   updateFavicon(notificationCounter);
// };
//
// const resetNotificationCounter = () => {
//   notificationCounter = 0;
//   link.href = `${window.location.origin}/favicon.png`;
//   updateFavicon(notificationCounter);
// };

export const Chat = ({ chatId, role = 'client', type = 'dialogs' }) => {
  const chatMessages = useSelector((state) => state.messagesSlice);
  const [page, setPage] = useState(1);
  const [isMessageSent, setIsMessageSent] = useState(false);
  const isFirstChatResolved = useRef(false);
  const { data: messages, refetch: fetchMessages } = useGetMessagesQuery(
    {
      id: chatId,
      role,
      type,
      page: !isMessageSent ? page : 1,
    },
    {
      skip: !chatId,
    }
  );
  const [isEditing, setIsEditing] = useState(false);
  const [editingText, setEditingText] = useState('');
  const [editedMessageId, setEditedMessageId] = useState('');
  const dispatch = useDispatch();

  const editMessageHandler = useCallback(
    (message) => {
      setIsEditing(true);
      setEditingText(message.text);
      setEditedMessageId(message.id);
    },
    [isEditing]
  );

  const messagesListConsideringHistory = useMemo(() => {
    if (page > 1) {
      return chatMessages.dialogsHistory.find(
        (dialog) => dialog.parentId === Number(chatId)
      );
    }
    // return {
    //   income: messages?.data?.client?.chat?.messages?.income,
    //   outcome: messages?.data?.client?.chat?.messages?.outcome,
    // };
  }, [chatMessages.dialogsHistory.length, chatId, page]);

  useLayoutEffect(() => {
    const pageFromHistory = chatMessages.dialogsHistory.find(
      (dialog) => dialog.parentId === Number(chatId)
    )?.page;
    if (pageFromHistory) {
      setPage(pageFromHistory);
    }
  }, [chatMessages.dialogsHistory.length, chatId]);

  useEffect(() => {
    if (page > 1) {
      dispatch(
        setDialogHistory({
          id: chatId,
          page,
          income: chatMessages.income || [],
          outcome: chatMessages.outcome || [],
        })
      );
    }
  }, [page, chatMessages.dialogsHistory.length]);

  useLayoutEffect(() => {
    if (role === 'client' && messages?.data?.status) {
      isFirstChatResolved.current = messages?.data?.status === 'resolved';
    }
    isFirstChatResolved.current = false;
    return () => {
      dispatch(clearMessages());
    };
  }, [chatId]);

  useEffect(() => {
    const resetEditing = (e) => {
      if (e.key === 'Escape') {
        setIsEditing(false);
        setEditingText('');
      }
    };
    if (isEditing) {
      document.addEventListener('keydown', resetEditing);
    }
    return () => {
      document.removeEventListener('keydown', resetEditing);
    };
  }, [isEditing]);

  useEffect(() => {
    // const visibilityHandler = () => {
    //   if (document.visibilityState === 'visible') {
    //     resetNotificationCounter();
    //   }
    // };

    // document.addEventListener('visibilitychange', visibilityHandler);

    const socketHandler = () => {
      fetchMessages();
    };

    const newMessageSocketHandler = (data) => {
      fetchMessages();
      // const isTabActive = document.visibilityState === 'visible';
      // console.log(data,'data')
      // if (data?.type === 'visitor' && !isTabActive) {
      //   increaseNotificationCounter();
      // } else {
      //   resetNotificationCounter();
      // }
    };

    if (type === 'support') {
      socket.on('support.message.send', socketHandler);
      socket.on('support.message.edit', socketHandler);
      socket.on('support.message.delete', socketHandler);
      socket.on('support.status', socketHandler);
    } else {
      socketHandler();
      socket.on('dialog.status', socketHandler);
      socket.on('dialog.connect', socketHandler);
      socket.on('dialog.message.send', newMessageSocketHandler);
      socket.on('dialog.message.edit', socketHandler);
      socket.on('dialog.message.delete', socketHandler);
      socket.on('dialog.message.save', socketHandler);
      socket.on('dialog.message.unsave', socketHandler);
    }

    return () => {
      if (type === 'support') {
        socket.off('support.message.send', socketHandler);
        socket.off('support.message.edit', socketHandler);
        socket.off('support.message.delete', socketHandler);
        socket.off('support.status', socketHandler);
      } else {
        socket.off('dialog.message.send', newMessageSocketHandler);
        socket.off('dialog.message.edit', socketHandler);
        socket.off('dialog.message.delete', socketHandler);
        socket.off('dialog.message.save', socketHandler);
        socket.off('dialog.message.unsave', socketHandler);
        socket.off('dialog.connect', socketHandler);
        socket.off('dialog.status', socketHandler);
        // document.removeEventListener('visibilitychange', visibilityHandler);
      }
    };
  }, [type, fetchMessages]);

  const uniqueMessages = useMemo(() => {
    const isFirstPage = page === 1;
    const income = isFirstPage
      ? chatMessages?.income
      : messagesListConsideringHistory?.income?.filter(
          (message, index, self) =>
            index ===
            self.findIndex(
              (m) =>
                m.text === message.text && m.created_at === message.created_at
            )
        );
    const outcome = isFirstPage
      ? chatMessages?.outcome
      : messagesListConsideringHistory?.outcome?.filter(
          (message, index, self) =>
            index ===
            self.findIndex(
              (m) =>
                m.text === message.text && m.created_at === message.created_at
            )
        );
    return {
      income,
      outcome,
    };
  }, [chatMessages?.income?.length, chatMessages?.outcome?.length]);

  const messagesList =
    role !== 'admin'
      ? uniqueMessages
      : messages?.data?.client?.chat?.messages || {
          income: [],
          outcome: [],
        };

  const status =
    type === 'dialogs'
      ? messages?.data?.status
      : role === 'admin'
      ? messages?.data?.client?.status
      : messages?.data?.status;

  const visitorId = messages?.data?.visitor?.id;

  const isChatActive =
    type === 'dialogs'
      ? !isFirstChatResolved.current &&
        messages?.data?.status !== 'resolved' &&
        type === 'dialogs'
      : messages?.data?.status;

  return (
    <ChatStyled className="chat">
      <ChatBody
        role={role}
        type={type}
        editMessage={editMessageHandler}
        chatId={chatId}
        total={messages?.data?.total}
        setPage={setPage}
        page={page}
        isChatActive={isChatActive}
        isResolved={isFirstChatResolved.current}
        visitorId={visitorId}
        status={status}
        messages={messagesList}
        setIsEditing={setIsEditing}
      />
      <ChatFooter
        key={chatId + status}
        role={role}
        type={type}
        onSend={setIsMessageSent}
        isChatActive={isChatActive && status !== 'waiting'}
        isSupport={type === 'support'}
        editedMessageId={editedMessageId}
        defaultValue={editingText}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        chatId={chatId}
      />
    </ChatStyled>
  );
};
